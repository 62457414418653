/*
    This file manages custom styling for the slick slider itself.
    Prefixing the classes to provide unique namespacing between onpage
    and modal galleries. The namespace is added to the parent of the ancestor
    to take advantage of the namespacing.
*/
.slick-next {
  right: -16px;
}

.slick-prev {
  left: -16px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-active {
  outline: #00aeef solid 2px;
  border-radius: 8px;
}

.galleryModal .slick-slider {
  height: inherit;
  display: flex;
  flex-direction: column;
}

.galleryModal.landscape .slick-slider {
  height: inherit;
  display: flex;
  flex-direction: row-reverse;
  width: inherit;
}

.galleryModal .slick-list {
  width: inherit;
  height: inherit;
  flex-grow: 1;
  flex-basis: 90%;
}

.galleryModal .slick-track {
  height: inherit !important;
}

.galleryModal .slick-slide > div {
  height: inherit;
  max-height: 900px;
}

.slick-slider .slick-list {
  height: 217px;

  @media (min-width: 768px) {
    height: 384px;
  }

  @media (min-width: 1024px) {
    height: 520px;
  }
}
